import { useCurrentUser } from '../../hooks/user';
import { useLocation } from 'wouter';

export function Blank() {
  const { user } = useCurrentUser();
  const [, setLocation] = useLocation();

  if (user) {
    setLocation('/checklists');
  }

  return null;
}
