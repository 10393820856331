import { getApiClient } from './client';
import { Param } from '../types/checklist';

export interface ParamDefinition {
  name: string;
  value: unknown;
}

const client = getApiClient();

export async function getParams({
  params,
  manifestId,
}: {
  manifestId: string;
  params: string[];
}) {
  const url = new URL('params', client.defaults.baseURL);
  url.searchParams.append('manifestId', manifestId);
  params.forEach((param) => url.searchParams.append('params', param));
  const { data } = await client.get<Record<string, Param<unknown>>>(
    url.toString()
  );
  return data;
}

export async function createParam(manifestId: string, param: ParamDefinition) {
  const { data } = await client.post<Param>(`/params`, {
    manifestId: manifestId,
    param,
  });

  return data;
}
