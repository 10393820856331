import { getApiClient } from './client';

export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
}

export interface User {
  _id: string;
  name: string;
  email: string;
  phone: string;
  roles: UserRoles[];
}

export async function getCurrentUser() {
  const client = getApiClient();
  const { data } = await client.get<User>('/user');
  return data;
}
