import { Page } from './Page';
import { useChecklistItems } from '../../hooks/checklists';
import { Button, CircularProgress, List, Menu, MenuItem } from '@mui/material';
import { ChecklistItem } from '../checklist/ChecklistItem';
import { useManifests } from '../../hooks/manifests';
import { useRef, useState } from 'react';
import { Manifest } from '../../types/manifest';
import { ArrowDropDown } from '@mui/icons-material';

export interface ChecklistPProps {
  includeCompleted?: boolean;
}

export function CheckList({ includeCompleted = true }: ChecklistPProps) {
  const manifestsMenuRef = useRef(null);
  const [manifest, setManifest] = useState<Manifest | undefined>();
  const [menuOpen, setMenuOpen] = useState(false);

  const { data: manifests, isLoading: isLoadingManifests } = useManifests();
  const { isLoading, data } = useChecklistItems({
    includeCompleted,
    manifestId: manifest?.id,
  });

  const doSetManifest = (manifest?: Manifest) => {
    setManifest(manifest);
    setMenuOpen(false);
  };
  return (
    <Page
      title="Checklist"
      appBar={
        !isLoadingManifests &&
        manifests && (
          <div ref={manifestsMenuRef}>
            <Button
              size="small"
              sx={{ color: 'white', cursor: 'pointer' }}
              onClick={() => setMenuOpen((v) => !v)}
            >
              <ArrowDropDown /> {manifest ? manifest.name : 'All pen houses'}
            </Button>
            <Menu open={menuOpen} anchorEl={manifestsMenuRef.current}>
              <MenuItem onClick={() => doSetManifest(undefined)}>
                All pen houses
              </MenuItem>

              {manifests.map((manifest) => (
                <MenuItem
                  key={manifest.id}
                  onClick={() => doSetManifest(manifest)}
                >
                  {manifest.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )
      }
    >
      {isLoading && (
        <div
          style={{
            display: 'grid',
            height: '100px',
            placeItems: 'center',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      {!isLoading && (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {data?.map((item) => <ChecklistItem item={item} key={item._id} />)}
        </List>
      )}
    </Page>
  );
}
