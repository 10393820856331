import { useQuery } from '@tanstack/react-query';
import { getCurrentUser, UserRoles } from '../api/user';
import { AxiosError } from 'axios';

export function useCurrentUser() {
  const { data, isLoading, error } = useQuery({
    queryKey: ['user', 'me'],
    queryFn: async () => {
      return getCurrentUser();
    },
    networkMode: 'always',
    retry: (failureCount, error: AxiosError) => {
      if (failureCount >= 3) {
        return false;
      }

      return !(
        error &&
        error.response &&
        [401, 403].includes(error.response.status as number)
      );
    },
  });

  return { user: data, isLoading, error };
}

export function useHasPermission(permission: UserRoles) {
  const { user, isLoading } = useCurrentUser();

  if (isLoading) {
    return false;
  }

  if (!user) {
    return false;
  }

  return user.roles.includes(permission);
}
