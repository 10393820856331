import { useContext } from 'react';
import { LoginContext } from '../contexts/LoginContext';
import { getApiClient } from '../api/client';
import { useLocation } from 'wouter';

export function useLogin() {
  const { showLogin, setShowLogin } = useContext(LoginContext);
  return { showLogin, setShowLogin };
}

export function useLogout() {
  const client = getApiClient();
  const { setShowLogin } = useLogin();
  const [, setLocation] = useLocation();

  return async () => {
    await client.post('/auth/logout');
    setLocation('/blank');
    setShowLogin(true);
  };
}
