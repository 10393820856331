import { ChecklistCompletionParam, ChecklistItem } from '../../types/checklist';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import formatRelative from 'date-fns/formatRelative';
import { Add, Check, Close, Edit } from '@mui/icons-material';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useCreateChecklistParam } from '../../hooks/checklists';

export function ChecklistItemParams({ item }: { item: ChecklistItem }) {
  const { completionParams = [] } = item;

  if (completionParams.length === 0) {
    return <div>This checklist does not have any parameters</div>;
  }

  return (
    <div>
      {completionParams.map((completionParam) => {
        return (
          <ChecklistItemParam
            completionParam={completionParam}
            item={item}
            key={completionParam.name}
          />
        );
      })}
    </div>
  );
}

export function ChecklistItemParam({
  completionParam,
  item,
}: {
  completionParam: ChecklistCompletionParam;
  item: ChecklistItem;
}) {
  const [showAddParam, setShowAddParam] = useState(false);
  const params = item.params.filter((p) => p.name === completionParam.name);
  const { allowMultiple } = completionParam;
  const createParam = useCreateChecklistParam();

  const handleAddParamClick = () => {
    setShowAddParam(true);
  };

  const handleOnParamSave = async (value: unknown) => {
    setShowAddParam(false);
    // TODO: save param
    const newParam = {
      name: completionParam.name,
      value,
    };
    await createParam.mutateAsync({ itemId: item._id, params: [newParam] });
  };

  const handleOnCancel = () => {
    setShowAddParam(false);
  };

  return (
    <>
      <Divider />
      <div key={completionParam.name}>
        <div>{completionParam.prompt}</div>
        <div>{completionParam.description}</div>
        <List>
          {params.map((param) => {
            console.log('showAddParam', showAddParam);
            console.log('allowMultiple', allowMultiple);
            return !showAddParam || allowMultiple ? (
              <ListItem key={param.id}>
                <ListItemText
                  primary={param.value}
                  secondary={formatRelative(
                    new Date(param.createDate),
                    new Date()
                  )}
                />
                {!allowMultiple && (
                  <div>
                    <IconButton>
                      <Edit onClick={handleAddParamClick} />
                    </IconButton>
                  </div>
                )}
              </ListItem>
            ) : null;
          })}
        </List>
        {showAddParam && (
          <NumberInputBox
            onSave={handleOnParamSave}
            onCancel={handleOnCancel}
            label={completionParam.prompt}
            value={!allowMultiple ? params[0]?.value || undefined : undefined}
          />
        )}
        {!showAddParam && (allowMultiple || params.length === 0) && (
          <div>
            <Button
              size="large"
              variant="contained"
              onClick={handleAddParamClick}
            >
              <Add /> Add
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

function NumberInputBox({
  onSave,
  onCancel,
  label,
  value: currentValue,
}: {
  onSave: (value: number) => void;
  onCancel: () => void;
  label?: string;
  value?: number;
}) {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number | undefined>(currentValue);

  const handleOnSave = useCallback(() => {
    if (value === undefined) return;
    onSave(value);
  }, [onSave, value]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    setValue(value);
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto auto', gap: 1 }}>
      <FormControl>
        <TextField
          size="small"
          ref={input}
          type="number"
          label={label}
          onChange={handleOnChange}
          value={currentValue}
        />
      </FormControl>
      <IconButton size="small" onClick={handleOnSave} color="primary">
        <Check />
      </IconButton>
      <IconButton size="small" onClick={onCancel} color="error">
        <Close />
      </IconButton>
    </Box>
  );
}
