import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { Title } from '../typography/Title';
import MenuIcon from '@mui/icons-material/Menu';
import { Inbox, Lock } from '@mui/icons-material';
import { useLogout } from '../../hooks/login';

export interface PageProps {
  title: string;
  children: ReactNode;
  /**
   * An item that will be rendered inside the app bar. This item will appear between the app title and the icons
   */
  appBar?: ReactNode;
}

export function Page({ title, children, appBar }: PageProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const logout = useLogout();

  const doLogout = async () => {
    setDrawerOpen(false);
    await logout().catch((e) => {
      console.error('Failed to logout', e);
    });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Title>{title}</Title>
          {appBar}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
        }}
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 250,
          }}
        >
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Inbox />
                </ListItemIcon>
                <ListItemText primary="Item" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={doLogout}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Container
        sx={{
          padding: '16px',
          height: 'calc(100vh - 2*56px)', // header and footer
          overflow: 'auto',
        }}
      >
        {children}
      </Container>
    </>
  );
}
