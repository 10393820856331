import { Page } from './Page';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  List,
  Typography,
} from '@mui/material';
import { useCreateManifest, useManifests } from '../../hooks/manifests';
import { ManifestControl } from '../manifest/ManifestControl';
import AddIcon from '@mui/icons-material/Add';
import { useHasPermission } from '../../hooks/user';
import { UserRoles } from '../../api/user';
import { ChangeEvent, Fragment, useState } from 'react';
import { FileUpload } from '../input/FileUpload/FileUpload';
import { validateManifest } from '../../api/manifests';
import { Manifest, ManifestConfig } from '../../types/manifest';
import format from 'date-fns/format';
import { AxiosError } from 'axios';

export function Manifests() {
  const { data: manifests, isLoading } = useManifests();
  const isAdmin = useHasPermission(UserRoles.ADMIN);
  const [showAddManifestDialog, setShowAddManifestDialog] = useState(false);

  return (
    <Page title="Pen house controls">
      {isLoading && (
        <div
          style={{
            display: 'grid',
            height: '100px',
            placeItems: 'center',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      {!isLoading && (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {manifests?.map((manifest) => (
            <ManifestControl key={manifest.id} manifest={manifest} />
          ))}
        </List>
      )}
      {isAdmin && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'absolute', bottom: 70, right: 20 }}
          onClick={() => setShowAddManifestDialog(true)}
        >
          <AddIcon />
        </Fab>
      )}
      {showAddManifestDialog && (
        <AddManifestDialog onClose={() => setShowAddManifestDialog(false)} />
      )}
    </Page>
  );
}

export function AddManifestDialog({ onClose }: { onClose: () => void }) {
  const [uploadError, setUploadError] = useState<AxiosError | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [manifestConfig, setManifestConfig] = useState<null | ManifestConfig>(
    null
  );
  const currentManifests = useManifests().data || [];
  const [manifestData, setManifestData] = useState<null | {
    data: string;
    type: string;
  }>(null);
  const createManifest = useCreateManifest();

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    doUploadManifest(file);
  };

  const handleFileDrop = (e: React.DragEvent<HTMLElement>) => {
    const file = e.dataTransfer?.files?.[0];
    if (!file) return;

    doUploadManifest(file);
  };

  const doUploadManifest = (file: File) => {
    setIsUploading(true);
    const type = file.type;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target?.result;
      if (!data) return;

      setUploadError(null);

      // Validate manifest
      try {
        const manifestConfig = await validateManifest(data as string, type);
        setManifestConfig(manifestConfig);
        setManifestData({ data: data as string, type });
      } catch (e) {
        setUploadError(e as any);
      } finally {
        setIsUploading(false);
      }
    };
    reader.readAsText(file);
  };

  const doSaveManifest = async () => {
    console.log('Saving manifest', { manifestData, manifestConfig });
    if (!manifestConfig || !manifestData) return;

    setIsUploading(true);
    try {
      await createManifest.mutateAsync({
        data: manifestData.data,
        type: manifestData.type,
      });
      onClose();
    } catch (e) {
      setUploadError(e as any);
    } finally {
      setIsUploading(false);
    }
  };

  if (isUploading) {
    return (
      <Dialog open={true}>
        <DialogTitle>Uploading manifest</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0',
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (manifestConfig) {
    return (
      <ManifestSummary
        setManifestConfig={setManifestConfig}
        manifestConfig={manifestConfig}
        currentManifests={currentManifests}
        uploadManifest={doSaveManifest}
      />
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Start a new pen house manifest</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 1 }}>
          To get started, upload the manifest file as JSON or YAML
        </DialogContentText>
        {uploadError && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {(uploadError.response?.data as any)?.message ||
              uploadError.message}
          </Alert>
        )}
        <FileUpload
          accept="application/json,application/x-yaml"
          onChange={handleFileUpload}
          onDrop={handleFileDrop}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

function ManifestSummary({
  setManifestConfig,
  manifestConfig,
  uploadManifest,
  currentManifests,
}: {
  setManifestConfig: (cfg: any) => void;
  uploadManifest: () => void;
  manifestConfig: ManifestConfig;
  currentManifests: Manifest[];
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>Manifest summary</DialogTitle>
      <DialogContent>
        {currentManifests.some((m) => m.id === manifestConfig.id) && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            A manifest with this ID already exists. Saving this manifest will
            overwrite the existing manifest
          </Alert>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '15px',
            alignItems: 'center',
          }}
        >
          <>
            <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
              Name
            </Typography>
            <Typography component="dd" variant="body2">
              {manifestConfig.name}
            </Typography>
          </>
          <>
            <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
              ID
            </Typography>
            <Typography component="dd" variant="body2">
              {manifestConfig.id}
            </Typography>
          </>
          <>
            <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
              Reference time
            </Typography>
            <Typography component="dd" variant="body2">
              {format(new Date(manifestConfig.referenceTime), 'PPpp')}
            </Typography>
          </>
          {Object.entries(manifestConfig.params).map(([name, value]) => (
            <Fragment key={name}>
              <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
                param.{name}
              </Typography>
              <Typography component="dd" variant="body2">
                {value as any}
              </Typography>
            </Fragment>
          ))}
          <>
            <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
              Checklists
            </Typography>
            <Typography component="dd" variant="body2">
              {manifestConfig.checklists?.length || 0}
            </Typography>
          </>
          <>
            <Typography component="dt" variant="h6" sx={{ fontSize: '16px' }}>
              Jobs
            </Typography>
            <Typography component="dd" variant="body2">
              {manifestConfig.jobs?.length || 0}
            </Typography>
          </>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setManifestConfig(null)}>Cancel</Button>
        <Button onClick={() => uploadManifest()}>Save Manifest</Button>
      </DialogActions>
    </Dialog>
  );
}
