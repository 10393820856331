import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createManifest, deleteManifest, getManifests } from '../api/manifests';

export function useManifests() {
  const { data, isLoading } = useQuery({
    queryKey: ['manifests'],
    queryFn: async () => {
      return getManifests();
    },
    networkMode: 'always', // TODO: find out what this means
  });

  return { data, isLoading };
}

export function useDeleteManifest() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (manifestId: string) => {
      await deleteManifest(manifestId);
      await queryClient.invalidateQueries({ queryKey: ['manifests'] });
    },
  });
}

export function useCreateManifest() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data, type }: { data: string; type: string }) => {
      await createManifest(data, type);
      await queryClient.invalidateQueries({ queryKey: ['manifests'] });
    },
  });
}
