import GoogleLogin from './GoogleLogin';
import { useEffect } from 'react';
import { useLogin } from '../../hooks/login';
import { AxiosError } from 'axios';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from '../../hooks/user';

export function Login() {
  const { showLogin, setShowLogin } = useLogin();
  const queryClient = useQueryClient();
  const { error } = useCurrentUser();

  useEffect(() => {
    const e = error as AxiosError;
    if (e && e.response && [401, 403].includes(e.response!.status)) {
      setShowLogin(true);
    }
  }, [error, setShowLogin]);

  useEffect(() => {
    // When user logs in or logs out, invalidate all cache
    queryClient.invalidateQueries().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLogin]);

  if (!showLogin) return null;

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    width: 'calc(100% - 30px)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
  };

  return (
    <Modal
      className="login-modal"
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Login to continue
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GoogleLogin />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
