import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigation from '@mui/material/BottomNavigation';
import Factory from '@mui/icons-material/Factory';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Dashboard from '@mui/icons-material/Dashboard';
import { NavItems } from '../constants';
import { useLocation } from 'wouter';

export function NavBar() {
  const [value, setValue] = useState('');
  const [location, setLocation] = useLocation();

  useEffect(() => {
    const [, newLocation = ''] = location.match(/\/([^/]+)/) || [];
    setValue(newLocation);
  }, [location]);

  const handleNavChange = (_: SyntheticEvent, newValue: any) => {
    // Navigate to the landing page of the selected nav if we're not already there
    const newLocation = `/${newValue}`;

    if (location === newLocation) {
      return;
    }

    setLocation(newLocation);
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value} onChange={handleNavChange}>
        <BottomNavigationAction
          label="Checklists"
          icon={<ChecklistIcon />}
          value={NavItems.CHECKLISTS}
        />
        <BottomNavigationAction
          label="Pen Houses"
          icon={<Factory />}
          value={NavItems.MANIFESTS}
        />
        <BottomNavigationAction
          label="Dashboard"
          icon={<Dashboard />}
          value={NavItems.DASHBOARD}
        />
      </BottomNavigation>
    </Paper>
  );
}
