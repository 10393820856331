import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createParam, getParams, ParamDefinition } from '../api/params';

export function useManifestParams(manifestId: string, params: Array<string>) {
  const cacheKeys = params.reduce(
    (acc, param) => {
      acc[param] = param;
      return acc;
    },
    {} as Record<string, string>
  );

  const { data, isLoading } = useQuery({
    queryKey: ['params', { manifestId }, cacheKeys],
    queryFn: async () => {
      return getParams({ manifestId, params });
    },
    networkMode: 'always',
  });

  return { data, isLoading };
}

export function useCreateParam() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      manifestId,
      param,
    }: {
      manifestId: string;
      param: ParamDefinition;
    }) => {
      const data = await createParam(manifestId, param);
      await queryClient.invalidateQueries({
        queryKey: ['params', { manifestId }],
      });
      return data;
    },
  });
}
