import { getApiClient } from './client';
import { Manifest } from '../types/manifest';

const client = getApiClient();

export async function getManifests() {
  const { data } = await client.get<Manifest[]>('/manifests');
  return data;
}

export async function deleteManifest(manifestId: string) {
  await client.delete(`/manifests/${manifestId}`);
}

export async function validateManifest(data: string, type: string) {
  const { data: serverData } = await client.post<Manifest>(
    '/manifests/validate',
    data,
    { headers: { 'content-type': type } }
  );
  return serverData;
}

export async function createManifest(data: string, type: string) {
  const { data: serverData } = await client.post<Manifest>('/manifests', data, {
    headers: { 'content-type': type },
  });
  return serverData;
}
