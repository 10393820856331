import { getApiClient } from './client';
import { ChecklistItem } from '../types/checklist';
import { ParamDefinition } from './params';

const client = getApiClient();

export interface ChecklistItemsFilter {
  manifestId?: string;
  includeCompleted?: boolean;
}

export async function getChecklistItems(
  filter: ChecklistItemsFilter = {}
): Promise<ChecklistItem[]> {
  const { data } = await client.get<ChecklistItem[]>('/checklists', {
    params: filter,
  });
  return data;
}

export async function createChecklistParams(
  itemId: string,
  params: ParamDefinition[]
) {
  const { data } = await client.post<ChecklistItem>(
    `/checklists/${itemId}/params`,
    params
  );

  return data;
}

export async function markChecklistCompleted(
  itemId: string,
  additionalParams: ParamDefinition[] = []
) {
  const { data } = await client.post<ChecklistItem>(
    `/checklists/${itemId}/completed`,
    additionalParams
  );
  return data;
}

export async function markChecklistUncompleted(itemId: string) {
  const { data } = await client.post<ChecklistItem>(
    `/checklists/${itemId}/uncompleted`
  );
  return data;
}
