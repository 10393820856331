import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createChecklistParams,
  getChecklistItems,
  markChecklistCompleted,
  markChecklistUncompleted,
} from '../api/checklist';
import { ParamDefinition } from '../api/params';

export function useChecklistItems({
  includeCompleted,
  manifestId,
}: {
  manifestId?: string;
  includeCompleted?: boolean;
}) {
  const { data, isLoading } = useQuery({
    queryKey: [
      'checklists-items',
      {
        includeCompleted,
        manifestId,
      },
    ],
    queryFn: async () => {
      return getChecklistItems({ manifestId, includeCompleted });
    },
    networkMode: 'always', // TODO: find out what this means
  });

  return {
    isLoading,
    data,
  };
}

export function useCreateChecklistParam() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      itemId,
      params,
    }: {
      itemId: string;
      params: Array<ParamDefinition>;
    }) => {
      const data = await createChecklistParams(itemId, params);
      await queryClient.invalidateQueries({ queryKey: ['checklists-items'] });
      return data;
    },
  });
}

export function useMarkCompleted() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      itemId,
      params = [],
    }: {
      itemId: string;
      params?: Array<{ name: string; value: unknown }>;
    }) => {
      const data = await markChecklistCompleted(itemId, params);
      await queryClient.invalidateQueries({ queryKey: ['checklists-items'] });
      return data;
    },
  });
}

export function useMarkUncompleted() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (itemId: string) => {
      const data = markChecklistUncompleted(itemId);
      await queryClient.invalidateQueries({ queryKey: ['checklists-items'] });
      return data;
    },
  });
}
