import { ChangeEvent, DragEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';

export type FileUploadProps = {
  accept: string;
  hoverLabel?: string;
  dropLabel?: string;
  className?: string;
  backgroundColor?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLElement>) => void;
};

const FileUploadLabel = styled.label`
  cursor: pointer;
  text-align: center;
  display: flex;
  border: 1px dashed #ccc;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 20px 0;

  &.drag-over {
    & img {
      opacity: 0.3;
    },
    & p, svg {
      opacity: 1;
    }
  }

  &:hover {
    border-color: #aaa;
  }
  
  &:hover p,&:hover svg,& img {
    opacity: 1;
  },
    
  & p, svg {
    opacity: 0.4;
  },
    
  &:hover img {
    opacity: 0.3;
  },
`;

export const FileUpload = ({
  accept,
  hoverLabel = 'Click or drag to upload file',
  dropLabel = 'Drop file here',
  backgroundColor = '#fff',
  onChange,
  onDrop,
  className,
}: FileUploadProps) => {
  const [labelText, setLabelText] = useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      onDrop(e);
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);

    if (inputRef.current) inputRef.current.value = '';
  };

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        ref={inputRef}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px 0',
        }}
      >
        <FileUploadLabel
          htmlFor="file-upload"
          {...dragEvents}
          className={clsx(className, isDragOver && 'drag-over')}
        >
          <Box
            bgcolor={backgroundColor}
            sx={{
              pointerEvents: 'none',
            }}
          >
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CloudUploadIcon fontSize="large" />
                <Typography>{labelText}</Typography>
              </Box>
            </>
          </Box>
        </FileUploadLabel>
      </div>
    </>
  );
};
