import { createTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      done: 'white', // Ok to go
      ok: green.A100, // Completed
      notOk: red.A100, // Something is still missing,
    },
  },
} as any);

export default theme;
