import { Login } from './components/login/Login';
import { NavBar } from './layout/NavBar';
import { Redirect, Route, Router } from 'wouter';
import { CheckList } from './components/pages/Checklist';
import { NavItems } from './constants';
import { Blank } from './components/pages/Blank';
import { Manifests } from './components/pages/Manifests';

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/">
          <Redirect to={`/${NavItems.CHECKLISTS}`} />
        </Route>
        <Route path={`/${NavItems.CHECKLISTS}`}>
          <CheckList />
        </Route>
        <Route path={`/${NavItems.MANIFESTS}`}>
          <Manifests />
        </Route>
        <Route path={`/blank`}>
          <Blank />
        </Route>
      </Router>
      <Login />
      <NavBar />
    </div>
  );
}

export default App;
