import { GOOGLE_CLIENT_ID } from '../../constants';
import { googleLogin } from '../../api/login';
import { useEffect, useRef, useState } from 'react';
import { useLogin } from '../../hooks/login';
import { AxiosError } from 'axios';
import { Alert, AlertTitle, Box } from '@mui/material';

export function GoogleLogin() {
  const [error, setError] = useState('');
  const { setShowLogin } = useLogin();
  const ref = useRef<HTMLDivElement>(null);
  const google = (window as any).google;
  const [, setGoogleForceUpdate] = useState(0);

  useEffect(() => {
    const handleCredentialResponse = async ({
      credential,
    }: {
      credential: string;
    }) => {
      setError('');
      try {
        await googleLogin(credential);
        setShowLogin(false);
      } catch (e) {
        const msg =
          (e as AxiosError<any>).response?.data?.message ||
          'An unexpected error prevented login. Check your network connection and try again.';
        setError(msg);
      }
    };

    if (typeof google === 'undefined') {
      setTimeout(() => {
        setGoogleForceUpdate((v) => v + 1);
      }, 200);
      return;
    }

    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });

    google.accounts.id.renderButton(
      ref.current,
      { theme: 'outline', size: 'large' } // customization attributes
    );
    google.accounts.id.prompt();
  }, [google, setShowLogin]);

  return (
    <Box
      className="google-login"
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      {!!error && (
        <Alert severity="error">
          <AlertTitle>Failed to login</AlertTitle>
          {error}
        </Alert>
      )}
      <div ref={ref}></div>
    </Box>
  );
}

export default GoogleLogin;
