import { ReactNode } from 'react';
import { Typography } from '@mui/material';

export function Title({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="h1"
      sx={{ flexGrow: 1, fontSize: '20px', fontWeight: 'bold' }}
    >
      {children}
    </Typography>
  );
}
