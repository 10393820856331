import { createContext, ReactNode, useState } from 'react';

const useLoginContextValue = () => {
  const [showLogin, setShowLogin] = useState(false);
  return {
    showLogin,
    setShowLogin,
  };
};

export const LoginContext = createContext<
  ReturnType<typeof useLoginContextValue>
>({} as any);

export function LoginContextProvider({ children }: { children: ReactNode }) {
  const contextValue = useLoginContextValue();
  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  );
}
