import axios from 'axios';
import { API_BASE_URL } from '../constants';

export interface ApiClientOptions {}

export function getApiClient() {
  return axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    validateStatus: (status) => {
      return status < 400;
    },
  });
}
