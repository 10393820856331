export const GOOGLE_CLIENT_ID =
  '1023198529074-p47n3bn8ickrj5iros9e2ol5ml3f23d0.apps.googleusercontent.com' as const;
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  `http://${window.location.hostname}:3100/`;

export enum NavItems {
  CHECKLISTS = 'checklists',
  MANIFESTS = 'manifests',
  DASHBOARD = 'dashboard',
}

export enum ParamKeys {
  LIGHT_STATUS = 'lightStatus',
  LIGHT_OVERRIDE = 'lightOverride',
  HOUSE = 'house',
}
