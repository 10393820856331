import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { useMarkCompleted, useMarkUncompleted } from '../../hooks/checklists';
import { ChecklistItem as ChecklistItemType } from '../../types/checklist';
import { useCallback, useState } from 'react';
import { ChecklistItemParams } from './ChecklistItemParams';

export function ChecklistItem({ item }: { item: ChecklistItemType }) {
  const { mutateAsync: markCompleted, isLoading: isLoadingCompleted } =
    useMarkCompleted();
  const { mutateAsync: markUncompleted, isLoading: isLoadingUncompleted } =
    useMarkUncompleted();
  const { completed, _id } = item;
  const isLoading = isLoadingCompleted || isLoadingUncompleted;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleCompletion = useCallback(async () => {
    // setIsCompleted(!completed);

    try {
      if (completed) {
        item.completed = false;
        await markUncompleted(_id);
      } else {
        item.completed = true;
        await markCompleted({ itemId: _id });
      }
    } catch {
      item.completed = !item.completed;
    }
  }, [completed, item, markUncompleted, _id, markCompleted]);

  const params = item.params || [];
  const allSet = item.completionParams.every(({ name, required }) => {
    if (!required) return true;
    return params.some((p) => p.name === name);
  });

  return (
    <ListItem key={item._id} disableGutters>
      <Box flexGrow={1}>
        <Card
          elevation={isExpanded ? 4 : 1}
          sx={{
            width: '100%',
            bgcolor: completed
              ? 'background.done'
              : allSet
              ? 'background.ok'
              : 'background.notOk',
          }}
        >
          <CardContent>
            <ListItemSecondaryAction sx={{ top: '64px' }}>
              <Checkbox
                edge="end"
                onChange={() => handleToggleCompletion()}
                checked={completed}
                disabled={isLoading || !allSet}
                sx={{ right: '15px' }}
              />
            </ListItemSecondaryAction>
            <ListItemButton
              onClick={() => setIsExpanded(!isExpanded)}
              disableGutters
              sx={{ marginRight: '25px' }}
            >
              <ListItemText
                id={item._id}
                primary={item.name}
                secondary={item.title}
              />
            </ListItemButton>
            <Collapse in={isExpanded}>
              <ChecklistItemParams item={item} />
            </Collapse>
          </CardContent>
        </Card>
      </Box>
    </ListItem>
  );
}
