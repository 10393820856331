import { Manifest } from '../../types/manifest';
import { ParamKeys } from '../../constants';
import { useCreateParam, useManifestParams } from '../../hooks/params';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, ReactNode, useEffect, useState, MouseEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DeleteForever } from '@mui/icons-material';
import { useHasPermission } from '../../hooks/user';
import { UserRoles } from '../../api/user';
import { useDeleteManifest } from '../../hooks/manifests';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type LightOverrideValue = {
  override: boolean;
  status: number;
};

export function ManifestControl({ manifest }: { manifest: Manifest }) {
  // Get params of interest
  const { data: params, isLoading } = useManifestParams(manifest.id, [
    ParamKeys.LIGHT_OVERRIDE,
    ParamKeys.LIGHT_STATUS,
    ParamKeys.HOUSE,
  ]);

  const house = params?.house?.value;
  const lightOverride = (params?.lightOverride?.value ||
    {}) as LightOverrideValue;
  const override = lightOverride.override || false;
  const status = lightOverride.status || 0;
  const [isSwitchBusy, setIsSwitchBusy] = useState(false);
  const isBusy = isLoading || isSwitchBusy;
  const createParam = useCreateParam();
  const [currentStatus, setCurrentStatus] = useState(status);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const isAdmin = useHasPermission(UserRoles.ADMIN);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutateAsync } = useDeleteManifest();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleActionButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const toggleLightOverride = async ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setIsSwitchBusy(true);

    // Create param to override
    const param = {
      name: ParamKeys.LIGHT_OVERRIDE,
      value: {
        override: checked,
        status,
      },
    };

    try {
      // Override light
      await createParam.mutateAsync({ manifestId: manifest.id, param });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSwitchBusy(false);
    }
  };

  const debounced = useDebouncedCallback(async (value: number) => {
    // setIsSwitchBusy(true);
    const status = value as number;
    const param = {
      name: ParamKeys.LIGHT_OVERRIDE,
      value: {
        override,
        status,
      },
    };

    try {
      // Override light
      await createParam.mutateAsync({ manifestId: manifest.id, param });
    } catch (e) {
      console.error(e);
    } finally {
      // setIsSwitchBusy(false);
    }
  }, 100);

  const handleChangeBrightness = async (_: Event, value: number | number[]) => {
    setCurrentStatus(value as number);
    debounced(value as number);
  };

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  const handleDeleteManifestClick = () => {
    setShowDeleteDialog(true);
    setEnableDelete(false);
  };

  const handleManifestDeleteNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEnableDelete(
      e.target.value.toLowerCase().trim() === manifest.name.toLowerCase().trim()
    );
  };

  const handleDeleteManifest = async () => {
    setShowDeleteDialog(false);
    setIsDeleting(true);
    try {
      await mutateAsync(manifest.id);
    } catch (e) {
      console.error('Failed to delete manifest', e);
    }
    setIsDeleting(false);
  };

  const actions: ReactNode[] = [];

  if (isAdmin) {
    // Add delete button to actions
    actions.push(
      <MenuItem key="delete" onClick={handleDeleteManifestClick}>
        <ListItemIcon>
          <DeleteForever sx={{ color: red[900] }} />
        </ListItemIcon>
        <ListItemText>
          <Typography color={red[600]}>Delete manifest</Typography>
        </ListItemText>
      </MenuItem>
    );
  }

  const marks = [
    {
      value: 0,
      label: 'Off',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 68,
      label: 'On',
    },
  ];

  return (
    <>
      <Card sx={{ opacity: isDeleting ? 0.5 : 1 }}>
        <CardHeader
          title={manifest.name}
          subheader={house ? `Pen House: ${house}` : ''}
          action={
            actions.length > 0 && (
              <>
                <IconButton
                  aria-label="settings"
                  onClick={handleActionButtonClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  open={open}
                  onClose={handleCloseActions}
                  anchorEl={anchorEl}
                >
                  {actions}
                </Menu>
              </>
            )
          }
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {manifest.name[0].toUpperCase()}
            </Avatar>
          }
        />
        <CardContent>
          <Stack spacing={4}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={override}
                    disabled={isBusy}
                    onChange={toggleLightOverride}
                    color="error"
                  />
                }
                label="Override pen house lighting"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel component="legend">Brightness level</FormLabel>
              <Slider
                value={currentStatus}
                aria-label="Default"
                valueLabelDisplay="auto"
                disabled={isBusy || !override}
                onChange={handleChangeBrightness}
                min={0}
                max={68}
                color="secondary"
                marks={marks}
              />
            </FormControl>
          </Stack>
        </CardContent>
      </Card>
      {isAdmin && (
        <Dialog open={showDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>
            Delete pen house manifest &quot;{manifest.name}&quot;?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to delete this pen house from the system. This
              action cannot be undone. The manifest will stop running, though
              all data will be preserved.
              <br />
              To continue, enter the name of the manifest below
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Name of manifest"
              type="text"
              fullWidth
              variant="standard"
              onInput={handleManifestDeleteNameInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button
              onClick={handleDeleteManifest}
              disabled={!enableDelete}
              color="error"
            >
              <DeleteForever />
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
